/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBbvX7pu_ujhLQf9RyhyN02YjmwoaLZKfA",
  "appId": "1:209438948172:web:1baa60da53f17ab00dade9",
  "authDomain": "schooldog-i-commerce-ga.firebaseapp.com",
  "measurementId": "G-GPBRTMH52L",
  "messagingSenderId": "209438948172",
  "project": "schooldog-i-commerce-ga",
  "projectId": "schooldog-i-commerce-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-commerce-ga.appspot.com"
}
